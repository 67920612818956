@import '~rsuite/lib/styles/themes/default/index.less';
@import url(https://fonts.googleapis.com/css?family=Raleway:500|Roboto:100,300,400&display=swap);

@base-color: #67b246;
@border-base-color: #67b246;
@secondary-color: #67b246;
@headings-font-family: Roboto, Helvetica, sans-serif;

html {
	--fc-button-border-color: #67b246;
	--fc-button-bg-color: #67b246;
}

.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
.rs-nav .rs-nav-item-active > .rs-nav-item-content,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:hover,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:focus,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
  color: #67b246;
}

.rs-sidenav-collapse-out .rs-nav-item-text,
.rs-sidenav-collapse-out .rs-dropdown-toggle > span {
  animation: none;
}

.fc-button-primary {
  background-color: #67b246 !important;
  border-color: #67b246 !important;
}

.fc-button-primary:hover {
  background-color: #27431a !important;
  border-color: #27431a !important;
}

.fc-button-primary:disabled {
  background-color: #798c71 !important;
  border-color: #798c71 !important;
}

.fc-event {
  background-color: #67b246 !important;
  border-color: #67b246 !important;
}

.fc-day {
  height: 40px !important;
}

.fc-scroller {
  height: auto !important;
}
